<template>
  <TTView>
    <VRow>
      <VCol>
        <CompetencyCard
          :companies="companies"
          :competency="competency"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <SkillsTable
          :loading="loading"
          :skills="competencySkills"
        >
          <template #topActions>
            <VBtn
              class="ml-3"
              color="primary"
              @click="addSkillDialog=true"
            >
              <VIcon left>
                fal fa-plus
              </VIcon>
              Добавить навык к компетенции
            </VBtn>
          </template>
          <template #rowActions="{ item }">
            <CompetencySkillEditForm
              :value="item"
              @update:level="item.level = $event"
              @update:parameters="item.parameters = $event"
              @submit="updateCompetencySkill(item)"
              @delete-skill="deleteSkillShow(item)"
            />
          </template>
        </SkillsTable>
      </VCol>
    </VRow>
    <CompetencyDeleteSkillDialog
      v-model="deleteSkillDialog"
      :item="skillForDelete"
      @click:outside="deleteSkillCancelDialog"
      @cancel="deleteSkillCancelDialog"
      @submit="deleteSkill"
    />
    <VDialog
      v-model="addSkillDialog"
      content-class="competency-skill-add-dialog"
      max-width="500px"
      @click:outside="addSkillCancelDialog"
    >
      <CompetencySkillAddForm
        v-if="addSkillDialog"
        :skills="skills"
        @update:searchSkills="fetchSkills($event)"
        @submit="addSkill($event)"
        @cancel="addSkillCancelDialog"
      />
    </VDialog>
  </TTView>
</template>

<script>
import { DEFAULT_COMPETENCY } from '../../components/competency-matrix/competencies/common';
import CompetencyCard from '../../components/competency-matrix/competencies/CompetencyCard.vue';
import SkillsTable from '../../components/competency-matrix/skills/SkillsTable.vue';
import CompetencySkillEditForm from '../../components/competency-matrix/competencies/skill/CompetencySkillEditForm.vue';
import CompetencySkillAddForm from '../../components/competency-matrix/competencies/skill/CompetencySkillAddForm.vue';
// eslint-disable-next-line max-len, vue/max-len
import CompetencyDeleteSkillDialog from '../../components/competency-matrix/competencies/skill/CompetencyDeleteSkillDialog.vue';

export default {
  name: 'CompetencySkills',

  components: {
    CompetencyCard,
    SkillsTable,
    CompetencySkillEditForm,
    CompetencySkillAddForm,
    CompetencyDeleteSkillDialog,
  },

  props: {
    competencyId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      companies: [],
      competency: { ...DEFAULT_COMPETENCY },
      competencySkills: [],
      loading: true,
      deleteSkillDialog: false,
      skillForDelete: {},
      addSkillDialog: false,
      skills: { loading: true, items: [] },
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const { competencyId } = this;
        const [
          companiesResponse,
          competencyResponse,
        ] = await Promise.all([
          this.$di.api.Account.indexCompany(),
          this.$di.api.CompetencyMatrix.CompetenciesGet({ id: competencyId }),
        ]);

        this.companies = companiesResponse.companies || [];
        this.competency = competencyResponse.competency || { ...DEFAULT_COMPETENCY };
        await this.fetchCompetencySkills();
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        // no-finally
        this.loading = false;
      }
    },

    async fetchCompetencySkills() {
      try {
        this.loading = true;
        const { competencyId } = this;
        const competencySkillsResponse = await this.$di.api.CompetencyMatrix.V2CompetencySkillsSkills(
          { data: { competencyId } },
        );
        this.competencySkills = competencySkillsResponse.competencySkills.map((skill) => ({
          ...skill,
          id: skill.skillId,
          name: skill.skillName,
          parameters: JSON.stringify(skill.parameters) || '{}',
        })) || [];
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        // no-finally
        this.loading = false;
      }
    },

    async fetchSkills(query = '') {
      this.skills.loading = true;
      try {
        const { skills } = await this.$di.api.CompetencyMatrix.SkillsSearch({ query });
        this.skills.items = skills;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.skills.loading = false;
      }
    },

    async addSkill(item) {
      try {
        this.loading = true;
        const { competencyId } = this.$route.params;
        const data = {
          competencySkill: {
            competencyId,
            ...item,
            parameters: JSON.parse(item.parameters),
          },
        };

        await this.$di.api.CompetencyMatrix.CompetencySkillsCreate(data);
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.addSkillCancelDialog();
        await this.fetchCompetencySkills();
      }
    },

    async deleteSkillShow(item) {
      this.skillForDelete = item;
      this.deleteSkillDialog = true;
    },

    async deleteSkill() {
      try {
        const { competencyId, skillForDelete: { id: skillId } } = this;
        const data = { skillId, competencyId };

        await this.$di.api.CompetencyMatrix.CompetencySkillsDelete(data);
        this.$di.notify.snackSuccess('Навык успешно удален из компетенции');
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.deleteSkillCancelDialog();
        await this.fetchCompetencySkills();
      }
    },

    deleteSkillCancelDialog() {
      this.skillForDelete = {};
      this.deleteSkillDialog = false;
    },

    addSkillCancelDialog() {
      this.addSkillDialog = false;
    },

    async updateCompetencySkill(item) {
      try {
        this.loading = true;
        const { competencyId } = this;
        const { skillId, level, parameters } = item;
        const data = {
          competencySkill: {
            skillId,
            competencyId,
            level,
            parameters: JSON.parse(parameters),
          },
        };

        await this.$di.api.CompetencyMatrix.CompetencySkillsUpdate(data);
        this.$di.notify.snackSuccess('Навык успешно обновлен');
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
  .competency-skill-add-dialog {
    overflow-y: visible;
  }
</style>
