<template>
  <VDialog
    :value="value"
    max-width="500px"
  >
    <VCard>
      <VCardTitle class="headline">
        Удалить навык
      </VCardTitle>

      <VCardText>
        Навык <span class="red--text">{{ item.name }}</span> будет удален! <br>
        Действительно хотите удалить навык?
      </VCardText>

      <VCardActions>
        <VSpacer />

        <VBtn
          text
          @click="$emit('cancel')"
        >
          Нет
        </VBtn>

        <VBtn
          color="red darken-1"
          text
          @click="$emit('submit')"
        >
          Да
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: 'CompetencyDeleteSkillDialog',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
