<template>
  <VCard>
    <VCardTitle>
      Добавление навыка к компетенции
    </VCardTitle>
    <VCardText>
      <VRow>
        <VCol cols="12">
          <TTAutocomplete
            v-model="skill.skillId"
            :search-input.sync="searchSkills"
            :items="skills.items"
            :filter="customFilter"
            :loading="skills.loading"
            :disabled="skills.loading"
            item-text="id"
            item-value="id"
            label="Навык"
            placeholder="Выберите навык"
            attach
          >
            <template #selection="{ item }">
              <VListItemContent
                class="px-3 tt-text-body-2"
                v-text="item.name"
              />
            </template>
            <template #item="{ item }">
              <VListItemContent>
                <VListItemTitle v-text="item.name" />
                <VListItemSubtitle v-text="item.id" />
              </VListItemContent>
            </template>
          </TTAutocomplete>
        </VCol>
        <VCol cols="12">
          <TTSelect
            v-model="skill.level"
            class="mx-1"
            label="Уровень"
            item-text="text"
            item-value="value"
            :items="skillLevels"
          />
        </VCol>
        <VCol cols="12">
          <VTextarea
            v-model="skill.parameters"
            outlined
            label="Параметры"
            hide-details="auto"
            placeholder="Тому кто введёт сюда невалидный JSON достанется наша ненависть и общественное порицание"
            :rules="[() => jsonIsValid || 'невалидный JSON']"
            @input="checkJson($event)"
          />
        </VCol>
      </vrow>
    </VCardText>

    <div class="d-flex pa-3">
      <VSpacer />

      <VBtn
        color="tt-secondary"
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="tt-primary"
        class="ml-3"
        :disabled="!jsonIsValid || !skill.skillId"
        @click="handleSubmit"
      >
        Добавить
      </VBtn>
    </div>
  </VCard>
</template>

<script>
export default {
  name: 'CompetencySkillAddForm',
  props: {
    skills: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      jsonIsValid: true,
      loading: false,
      skillLevels: [
        { text: 'LEVEL_NOVICE', value: 'LEVEL_NOVICE' },
        { text: 'LEVEL_BASIC', value: 'LEVEL_BASIC' },
        { text: 'LEVEL_PROFICIENT', value: 'LEVEL_PROFICIENT' },
        { text: 'LEVEL_ADVANCED', value: 'LEVEL_ADVANCED' },
        { text: 'LEVEL_EXPERT', value: 'LEVEL_EXPERT' },
      ],
      skill: { skillId: null, level: 'LEVEL_BASIC', parameters: '{}' },
      searchSkills: '',
    };
  },
  watch: {
    searchSkills() {
      this.$emit('update:searchSkills', this.searchSkills);
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },
    handleCancel() {
      this.$emit('cancel');
    },
    handleSubmit() {
      this.$emit('submit', this.skill);
    },
    async checkJson(json) {
      try {
        this.jsonIsValid = true;
        if (json) {
          await JSON.parse(json);
          this.skill.parameters = json;
        } else {
          this.skill.parameters = '{}';
        }
      } catch (e) {
        this.jsonIsValid = false;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-select__selections{
  display: flex;
}
</style>
