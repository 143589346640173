<template>
  <div v-if="value.id">
    <div class="d-flex align-center justify-end">
      <VSelect
        class="mx-1"
        label="Уровень"
        item-text="text"
        item-value="value"
        :items="skillLevels"
        :value="value.level"
        @input="$emit('update:level', $event || skillLevels[0].value)"
      />

      <VTooltip
        left
      >
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            dark
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click="$emit('delete-skill')"
          >
            <VIcon small>
              fal fa-minus
            </VIcon>
          </VBtn>
        </template>

        <span>Удалить</span>
      </VTooltip>
    </div>
    <div
      class="d-flex  flex-column justify-start"
    >
      <VTextarea
        :value="value.parameters"
        outlined
        label="Параметры"
        hide-details="auto"
        placeholder="Тому кто введёт сюда невалидный JSON достанется наша ненависть и общественное порицание"
        :rules="[() => jsonIsValid || 'невалидный JSON']"
        @input="checkJson($event)"
      />
      <VBtn
        color="success"
        class="my-2"
        :disabled="!jsonIsValid"
        @click="$emit('submit', value)"
      >
        update
      </VBtn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompetencySkillEditForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      jsonIsValid: true,
      skillLevels: [
        { text: 'LEVEL_NOVICE', value: 'LEVEL_NOVICE' },
        { text: 'LEVEL_BASIC', value: 'LEVEL_BASIC' },
        { text: 'LEVEL_PROFICIENT', value: 'LEVEL_PROFICIENT' },
        { text: 'LEVEL_ADVANCED', value: 'LEVEL_ADVANCED' },
        { text: 'LEVEL_EXPERT', value: 'LEVEL_EXPERT' },
      ],
    };
  },
  methods: {
    async checkJson(json) {
      try {
        this.jsonIsValid = true;
        if (json) {
          await JSON.parse(json);
          this.$emit('update:parameters', json);
        } else {
          this.$emit('update:parameters', '{}');
        }
      } catch (e) {
        this.jsonIsValid = false;
      }
    },
  },
};
</script>
